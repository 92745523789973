import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

class Introduction extends Component {

  componentDidMount () {
    const {
      userLanguage,
      getIntroduction
    } = this.props;

    getIntroduction(userLanguage);
  }

  componentDidUpdate (prevProps) {
    const {
      userLanguage,
      getIntroduction
    } = this.props;
    
    if (prevProps.userLanguage !== userLanguage) {
      getIntroduction(userLanguage);
    }
  }

  render () {
    const {
      content,
      loadingIntroduction
    } = this.props;

    return (
      loadingIntroduction ? (
        <div>loading...</div>
      ) : (
        <div className="scene-intro" style={
          {backgroundImage: content.heroImageUrl ? `url(${content.heroImageUrl})` : 'none'}
        }>
          <div className="section-intro__container">
            <div className="section-intro">
              <h1 className="section-intro__title">{content.heroTitle}</h1>
              <h3 className="section-intro__copy">{content.heroExcerpt}</h3>
              {content.ctaButtonName &&
                <div className="section-intro__cta btn">
                  <Link to="/intro/0">
                    {content.ctaButtonName}
                  </Link>
                </div>
              }
            </div>
          </div>
        </div>
      )
    );
  }
}

Introduction.propTypes = {
  content: PropTypes.object.isRequired,
  loadingIntroduction: PropTypes.bool.isRequired,
  getIntroduction: PropTypes.func.isRequired,
  userLanguage: PropTypes.string.isRequired
};

export default Introduction;
