import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import TagManager from 'react-gtm-module';

import logoFacebook from '../../images/plan/logo-facebook.svg';
import logoInstagram from '../../images/plan/logo-instagram.svg';
import ctaAirQuality from '../../images/plan/air-quality.svg';
import ctaSun from '../../images/plan/sun.svg';

class Plan extends Component {
  constructor () {
    super();
    this.state = {
      randomIndex: Math.floor(Math.random() * Math.floor(2))
    }
  }

  componentDidMount () {
    const {randomIndex} = this.state;
    const {content} = this.props;

    if (Object.keys(content).length === 0) {
      return <Redirect to="/" />
    }

    const flipRandomIndex = randomIndex === 0 ? 1 : 0;
    const daylightContent = this.getCtas().daylight[randomIndex];
    const indoorContent = this.getCtas().indoor[flipRandomIndex];

    TagManager.dataLayer({
      dataLayerName: 'PageDataLayer',
      dataLayer: {
        'event': 'interactionevent',
        'eventcategory': 'ctas',
        'eventaction': 'presentedCtas',
        'eventlabel': '',
        'eventvalue': `${daylightContent.title}, ${indoorContent.title}`
      }
    });

    TagManager.dataLayer({
      dataLayerName: 'PageDataLayer',
      dataLayer: {
        'event': 'interactionevent',
        'eventcategory': 'QuestionAnswer',
        'eventaction': 'SeePlan',
        'eventlabel': 'Plan',
        'eventvalue':  null
      }
    });
  }

  getCookie(name) {
    return document.cookie.split('; ').reduce((r, v) => {
      const parts = v.split('=')
      return parts[0] === name ? decodeURIComponent(parts[1]) : r
    }, '')
  }

  getCtas () {
    const {content} = this.props;

    switch(this.getCookie('upcomingRenovation')) {
    case '3':
    case '2':
      return content.renovationsPlanned;
    case '0':
      return content.considerRenovation;
    default:
      return content.justLooking;
    }
  }

  submitSelectedCta (url) {
    TagManager.dataLayer({
      dataLayerName: 'PageDataLayer',
      dataLayer: {
        'event': 'interactionevent',
        'eventcategory': 'ctas',
        'eventaction': 'ctaClick',
        'eventlabel': '0',
        'eventvalue': url
      }
    });
  }

  render () {
    const {randomIndex} = this.state;
    const {
      content,
      addedPlans
    } = this.props;

    if (Object.keys(content).length === 0) {
      return <Redirect to="/" />
    }

    const flipRandomIndex = randomIndex === 0 ? 1 : 0;
    const daylightContent = this.getCtas().daylight[randomIndex];
    const indoorContent = this.getCtas().indoor[flipRandomIndex];

    return (
      <div className="plan">

        <div className="plan__header">
          <h1 className="plan__header-title">
            {content.title}
          </h1>
          <h3 className="plan__header-copy">
            {content.description}
          </h3>

          <button className="plan__header-cta" onClick={() => window.print()}>
            {content.downloadButtonText}
          </button>
        </div>

        <ol className="plan__tips">
          {addedPlans.map((plan, index) =>
            <li className="plan__tip" key={index}>
              <h3 className="plan__tips-title">
                {plan.title}
              </h3>
              <p className="plan__tips-copy">
                {plan.description}
              </p>
            </li>
          )}
        </ol>

        <div className="plan__cta-wrapper">
          <div className="plan__cta">
            <div className="plan__cta-graphic">
              <img src={ctaSun} alt="Checkmark" className="plan__cta-img" />
            </div>
            <div className="plan__cta-content">
              <p className="plan__cta-small">
                {daylightContent.title}
              </p>
              <p className="plan__cta-title">
                {daylightContent.excerpt}
              </p>
              <p className="plan__cta-body">
                {daylightContent.description}
              </p>
              <a
                href={daylightContent.url}
                rel="noopener noreferrer"
                target="_blank"
                className="plan__cta-link"
                onClick={() => this.submitSelectedCta(daylightContent.url)}>
                {daylightContent.linkText}
              </a>
            </div>
          </div>

          <div className="plan__cta">
            <div className="plan__cta-graphic">
              <img src={ctaAirQuality} alt="Checkmark" className="plan__cta-img" />
            </div>
            <div className="plan__cta-content">
              <p className="plan__cta-small">
                {indoorContent.title}
              </p>
              <p className="plan__cta-title">
                {indoorContent.excerpt}
              </p>
              <p className="plan__cta-body">
                {indoorContent.description}
              </p>
              <a
                href={indoorContent.url}
                rel="noopener noreferrer"
                target="_blank"
                className="plan__cta-link"
                onClick={() => this.submitSelectedCta(indoorContent.url)}>
                {indoorContent.linkText}
              </a>
            </div>
          </div>
        </div>

        <div className="plan__some">
          <div className="plan__some-wrapper">
            <p className="plan__some-text">
              {content.someExcerpt}
            </p>

            <div className="plan__some-item">
              <a href="https://www.facebook.com/velux" target="_blank" className="plan__some-link" rel="noopener noreferrer">
                <img src={logoFacebook} alt="Facebook" className="plan__some-img" />
                <span className="plan__some-label">Facebook</span>
              </a>
            </div>

            <div className="plan__some-item">
              <a href="https://www.instagram.com/veluxgroup/" target="_blank" className="plan__some-link" rel="noopener noreferrer">
                <img src={logoInstagram} alt="Instagram" className="plan__some-img" />
                <span className="plan__some-label">Instagram</span>
              </a>
            </div>
          </div>
        </div>

        {content.additional && [
          <h3 className="plan__section-title" key="additional-title">
            {content.additional.title}
          </h3>,
          <div className="plan__recommendations" key="additional-recommendations">
            <div className="plan__recommendations-inner">
              {content.additional.tips.map((tip, index) =>
                <div className="plan__recommendation" key={index}>
                  <p className="plan__recommendation-title">
                    {tip.title}
                  </p>
                  <p className="small">
                    {tip.excerpt}
                  </p>
                  <a href={tip.url} rel="noopener noreferrer" target="_blank" className="plan__recommendation-cta">
                    {tip.linkText}
                  </a>
                </div>
              )}
            </div>
          </div>
        ]}
      </div>
    );
  }
}

Plan.propTypes = {
  addedPlans: PropTypes.array.isRequired,
  content: PropTypes.object.isRequired
};

export default Plan;
