import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  Link,
  Redirect,
  withRouter
} from 'react-router-dom';
import TagManager from 'react-gtm-module';
import _ from 'lodash';

import MultipleAnswers from './MultipleAnswers';
import Overlay from './Overlay';
import Scene from './Scene';

class QuestionStep extends Component {
  constructor () {
    super();
    this.state = {
      ctaActive: -1,
      showOverlay: false,
      enableClearButton: false,
      disableCtaButtons: false,
      hideQuestions: true
    };
    this.submitAnswer = this.submitAnswer.bind(this);
    this.toggleClearButton = this.toggleClearButton.bind(this);
  }

  componentDidMount () {
    const {
      match,
      steps,
      getScene
    } = this.props;

    if (!steps) {
      return <Redirect to="/" />;
    }

    getScene(steps[match.params.step].scene);
  }

  componentWillUnmount () {
    this.setState({
      enableClearButton: false,
      disableCtaButtons: false
    });
    clearTimeout(this.timer);
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      disableCtaButtons,
      hideQuestions
    } = this.state;
    const {
      match,
      steps,
      getScene
    } = this.props;
    const currentStepScene = steps[match.params.step].scene;
    const previousStepScene = steps[prevProps.match.params.step].scene;

    if (previousStepScene !== currentStepScene) {
      getScene(currentStepScene);      
    }

    if (prevState.hideQuestions && hideQuestions) {           
      this.setState({                    
        hideQuestions: false          
      });      
    }

    if (prevState.disableCtaButtons && disableCtaButtons) {
      this.setState({
        disableCtaButtons: false
      });
    }
  }

  goToNextStep (skipNextStep) {
    const {
      history,
      steps,
      questions
    } = this.props;
    const totalSteps = steps.length - 1;
    const nextStepNumber = skipNextStep
      ? this.stepNumber + 2
      : this.stepNumber + 1;

    if (nextStepNumber <= totalSteps) {
      return history.push(`/${this.category}/${nextStepNumber}`);
    }
    return history.push(questions.nextFlow);
  }

  submitAnswer (answer, keyname, index) {
    const currentDate = new Date();
    const expireDate = new Date(currentDate.setDate(currentDate.getDate() + 1));

    document.cookie = `${keyname}=${answer.score}; expires="${expireDate}";`;
    this.setState({
      ctaActive: index,
      disableCtaButtons: true,      
      hideQuestions: true
    });        

    if (answer.showFigures.length > 0) {
      this.showSceneFigures(answer.showFigures);
    }
    this.submitTrackingValues(keyname, answer.trackingValue, answer.score, answer.rawTrackingValues);
    this.addAnswerToStore(keyname, answer.score);
    this.timer = setTimeout(() => {
      this.deleteMultipleAnswerSceneFigures();
      this.goToNextStep(answer.skipNextStep);      
    }, 1200);
  }

  togglePopup () {
    const {showOverlay} = this.state;

    this.setState({
      showOverlay: !showOverlay
    });
  }

  showSceneFigures(figures) {
    const {
      figureIndexes,
      setFigureAnimationIndexes,
      updateFigureAnimationIndexes
    } = this.props;

    if (figureIndexes.length === 0) {
      setFigureAnimationIndexes(figures);
    } else {
      updateFigureAnimationIndexes(figures);
    }
  }

  deleteMultipleAnswerSceneFigures () {
    const {
      deleteMultipleFigureCount,
      multipleFigureCount
    } = this.props;

    if (multipleFigureCount.length > 0) {
      deleteMultipleFigureCount();
    }
  }

  addAnswerToStore (key, score) {
    const {
      answers,
      addAnswer,
      updateAnswer
    } = this.props;

    for (let index = 0; index < answers.length; index++) {
      if (answers[index].key === key) {
        return updateAnswer(key, score, 1);
      }
    }
    return addAnswer(key, score, 1);
  }

  submitTrackingValues (cookieName, trackingValue, score, rawTrackingValues) {
    const {trackingDetails} = this.props;
    const trackingKeynames = Object.keys(trackingDetails);

    _.find(trackingKeynames, key => {
      if (key === cookieName) {
        const question = trackingDetails[key];

        TagManager.dataLayer({
          dataLayerName: 'PageDataLayer',
          dataLayer: {
            'event': question.event,
            'eventcategory': question.eventcategory,
            'eventaction': question.eventaction,
            'eventlabel': question.eventlabel,
            'eventvalue': trackingValue
          }
        });

        if (question.extraTracking) {
          if (rawTrackingValues) {
            for (let index = 0; index < rawTrackingValues.length; index++) {
              if (rawTrackingValues[index].count > 0) {
                const action = question.extraTracking.eventaction[rawTrackingValues[index].cookieName]
                TagManager.dataLayer({
                  dataLayerName: 'PageDataLayer',
                  dataLayer: {
                    'event': question.event,
                    'eventcategory': question.extraTracking.eventcategory,
                    'eventaction': action,
                    'eventlabel': question.extraTracking.eventlabel,
                    'eventvalue': null       
                  }
                });
              }
            }
          } else {
            TagManager.dataLayer({
              dataLayerName: 'PageDataLayer',
              dataLayer: {
                'event': question.event,
                'eventcategory': question.extraTracking.eventcategory,
                'eventaction': question.extraTracking.eventaction[score],
                'eventlabel': question.extraTracking.eventlabel,
                'eventvalue': null       
              }
            });
          }
        }
      }
    });
  }

  toggleClearButton(enable) {
    const {resetMultipleFigureCount} = this.props;
    this.setState({
      enableClearButton: enable
    });

    if (!enable) {
      resetMultipleFigureCount();
    }
  }

  render () {
    const {
      ctaActive,
      showOverlay,
      enableClearButton,
      disableCtaButtons,
      hideQuestions
    } = this.state;

    const {
      match,
      steps,
      sceneData,
      loadingScene,
      figureIndexes,
      questions,
      multipleFigureCount,
      setMultipleFigureCount,
      updateMultipleFigureCount,
      totalQuestions,
      currentQuestionCount
    } = this.props;
    this.category = match.params.category;
    this.stepNumber = Number(match.params.step);
    const content = steps ? steps[this.stepNumber] : '';

    if (!content) {
      return <Redirect to="/" />;
    }

    return (
      <div className={`scene scene-${questions.flowId}-${this.stepNumber}`}>
        <button
          onClick={() => this.togglePopup()}
          className={cx('question__tip', {
            'question__tip--active' : showOverlay
          })}>

          <span className="sr-only">
            {content.whyWeAsk.title}
          </span>
        </button>

        {loadingScene ? <div className="scene__empty"></div> :
          <div>
            <Scene
              sceneData={sceneData}
              figureIndexes={figureIndexes}
              multipleFigureCount={multipleFigureCount}
            />        

            <div className={cx('container', 'question', {
              'animate-in' : !hideQuestions
            })}> 
              <p className="question__text">{content.question}</p>

              <div className={cx('controllers', {
                'controllers--2' : !content.multipleAnswers && content.answers.length === 2,
                'controllers--4' : !content.multipleAnswers && content.answers.length === 4,
              })}>
                {content.multipleAnswers ? (
                  <MultipleAnswers
                    content={content}
                    questions={questions}
                    submitAnswer={this.submitAnswer}
                    multipleFigureCount={multipleFigureCount}
                    setMultipleFigureCount={setMultipleFigureCount}
                    updateMultipleFigureCount={updateMultipleFigureCount}
                    toggleClearButton={this.toggleClearButton}
                    enableCtaButton={enableClearButton}
                  />
                ) : (
                  content.answers.map((answer, index) =>
                    disableCtaButtons ? (
                      <button
                        key={index}
                        className={cx('btn', 'js-btn-progress', {
                          'btn--nohover': ctaActive === index
                        })}>
                        {answer.text}
                      </button>
                    ) : (
                      <button
                        key={index}
                        className={cx('btn', 'js-btn-progress', {
                          'btn--nohover': ctaActive === index
                        })}
                        onClick={() => this.submitAnswer(answer, content.cookieKeyName, index)}>
                        {answer.text}
                      </button>
                    )
                  )
                )}
              </div>
            </div>          

            <div className="container u-hide-mobile question__text--desktop">
              <p className="small">
                <strong>{content.whyWeAsk.title}</strong>
              </p>
              <p className="small">
                {content.whyWeAsk.description}
              </p>
            </div>
          </div>
        }

        {showOverlay &&
          <Overlay>
            <div className="overlay__inner">
              <div className="overlay__text-container">
                <h3>{content.whyWeAsk.title}</h3>
                <p>{content.whyWeAsk.description}</p>
                {content.whyWeAsk.tipsTitle &&
                  <p>
                    <strong>{content.whyWeAsk.tipsTitle}</strong>
                  </p>
                }
                {content.whyWeAsk.tipsText &&
                  <p>{content.whyWeAsk.tipsText}</p>
                }
              </div>
              <button className="btn" onClick={() => this.togglePopup()}>
                {content.whyWeAsk.closeButtonText}
              </button>
            </div>
          </Overlay>
        }

        <div className={cx('container', 'footer-nav', {
          'footer-nav--simple': this.stepNumber === 0
        })}>
          {this.stepNumber > 0 &&
            <Link to={`/${this.category}/${this.stepNumber - 1}`} className="btn__plain">
              {questions.backButtonName}
            </Link>
          }
          {enableClearButton &&
            <button
              className="btn__plain btn__clear-selections u-hide-desktop"
              onClick={() => this.toggleClearButton(false)}>
              {questions.clearButtonName}
            </button>
          }

          <div className="progress-bar">
            <span style={
              {width: `${currentQuestionCount / totalQuestions * 100}%`}
            }>
              <span className="sr-only">
                Step {currentQuestionCount} of {totalQuestions} steps.
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

QuestionStep.propTypes = {
  match: PropTypes.object.isRequired,
  steps: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  questions: PropTypes.object.isRequired,
  getScene: PropTypes.func.isRequired,
  sceneData: PropTypes.object.isRequired,
  loadingScene: PropTypes.bool.isRequired,
  answers: PropTypes.array.isRequired,
  addAnswer: PropTypes.func.isRequired,
  updateAnswer: PropTypes.func.isRequired,
  figureIndexes: PropTypes.array.isRequired,
  setFigureAnimationIndexes: PropTypes.func.isRequired,
  updateFigureAnimationIndexes: PropTypes.func.isRequired,
  updateMultipleFigureCount: PropTypes.func.isRequired,
  setMultipleFigureCount: PropTypes.func.isRequired,
  multipleFigureCount: PropTypes.array.isRequired,
  totalQuestions: PropTypes.number.isRequired,
  currentQuestionCount: PropTypes.number.isRequired,
  resetMultipleFigureCount: PropTypes.func.isRequired,
  deleteMultipleFigureCount: PropTypes.func.isRequired,
  trackingDetails: PropTypes.object.isRequired
};

export default withRouter(QuestionStep);
