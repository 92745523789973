import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

class MultipleAnswers extends Component {
  constructor () {
    super();
    this.state = {
      disableCtaButtons: false
    }
  }

  componentDidMount () {
    const {
      content,
      setMultipleFigureCount
    } = this.props;

    content.answers.map(answer => {
      setMultipleFigureCount({
        cookieName: answer.multiAnswerCookie,
        count: 0
      });
    });
  }

  componentWillUnmount () {
    const {toggleClearButton} = this.props;

    this.setState({
      disableCtaButtons: false
    });
    toggleClearButton(false);
  }

  selectAnswer (index, keyname, score) {
    const {
      toggleClearButton,
      updateMultipleFigureCount
    } = this.props;
    const currentDate = new Date();
    const expireDate = new Date(currentDate.setDate(currentDate.getDate() + 1));

    document.cookie = `${keyname}=${score + 1}; expires="${expireDate}";`;

    updateMultipleFigureCount(1, index);
    toggleClearButton(true);
  }

  prepareSubmit () {
    const {
      content,
      submitAnswer
    } = this.props;

    this.setState({
      disableCtaButtons: true
    });

    const answer = {
      showFigures: [],
      score: this.getTotalScore(),
      trackingValue: content.cookieKeyName === 'windowsTotal'
        ? this.getEachItemCountScore()
        : this.getTotalScore(),
      rawTrackingValues: content.cookieKeyName === 'windowsTotal'
        ? this.props.multipleFigureCount
        : null
    };
    submitAnswer(answer, content.cookieKeyName);
  }

  getTotalScore () {
    const {multipleFigureCount} = this.props;
    let totalScore = 0;

    multipleFigureCount.map(figure =>
      totalScore = totalScore + figure.count
    );
    return totalScore;
  }

  getEachItemCountScore () {
    const {multipleFigureCount} = this.props;
    let itemCountScores = '';

    multipleFigureCount.map(figure =>
      itemCountScores += `${figure.cookieName}=${figure.count}; `
    );
    return itemCountScores;
  }

  render () {
    const {disableCtaButtons} = this.state;
    const {
      content,
      questions,
      multipleFigureCount,
      enableCtaButton,
      toggleClearButton
    } = this.props;

    return [
      <div className="horizontal-scroll" key="horizontalLeft">
        <div className="horizontal-scroll__inner">
          {multipleFigureCount.length > 0 &&
            content.answers.map((answer, index) =>
              <div className="btn__toggle" key={index}>
                <button
                  disabled={disableCtaButtons}
                  className={cx('btn__toggle-label')}
                  onClick={() => this.selectAnswer(index, answer.multiAnswerCookie, multipleFigureCount[index].count)}>
                  &#43; {answer.text}
                </button>
              </div>
            )
          }
        </div>
      </div>,
      <div className="horizontal-scroll__secondary" key="horizontalRight">
        {enableCtaButton ? [
          <button
            key="submit-button"
            disabled={disableCtaButtons}
            className="btn js-btn-progress"
            onClick={() => this.prepareSubmit()}>
            {questions.nextButtonName}
          </button>,
          <button
            key="clear-button"
            className="btn__plain btn__clear-selections u-hide-mobile"
            onClick={() => toggleClearButton(false)}>
            {questions.clearButtonName}
          </button>
        ] : (
          <button className="btn js-btn-progress" disabled={true}>
            {questions.nextButtonName}
          </button>
        )}
      </div>
    ];
  }
}

MultipleAnswers.propTypes = {
  content: PropTypes.object.isRequired,
  submitAnswer: PropTypes.func.isRequired,
  multipleFigureCount: PropTypes.array.isRequired,
  setMultipleFigureCount: PropTypes.func.isRequired,
  updateMultipleFigureCount: PropTypes.func.isRequired,
  toggleClearButton: PropTypes.func.isRequired,
  enableCtaButton: PropTypes.bool.isRequired,
  questions: PropTypes.object.isRequired
};

export default MultipleAnswers;
