import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  Link,
  Redirect,
  withRouter
} from 'react-router-dom';
import cx from 'classnames';
import TagManager from 'react-gtm-module';

class IntroductionStep extends Component {

  componentDidMount () {
    TagManager.dataLayer({
      dataLayerName: 'PageDataLayer',
      dataLayer: {
        'event': 'interactionevent',
        'eventcategory': 'QuestionAnswer',
        'eventaction': 'StartSurvey',
        'eventlabel': 'Start'
      }
    });
  }

  render () {
    const {
      steps,
      content,
      match
    } = this.props;
    const stepNumber = Number(match.params.step);

    if (!steps) {
      return <Redirect to="/" />;
    }

    return (
      <div className={`intro-step scene-1-${stepNumber + 1}`}>
        <div className="intro-step__image-wrapper">
          <img src={steps[stepNumber].heroImage} alt="hero image" className="intro-step__image" />
        </div>

        <p className="intro-step__title">
          <strong>{steps[stepNumber].title}</strong>
        </p>
        <p className="intro-step__copy">{steps[stepNumber].description}</p>
        <div>
          <ul className="question__pagination">
            {steps.map((step, index) =>
              <li
                key={index}
                className={cx('question__pagination-circle', {
                  'question__pagination-circle--active': index === stepNumber
                })}>
                <span className="sr-only">
                  Step {stepNumber + 1}
                </span>
              </li>
            )}
          </ul>
        </div>

        <div className="intro-step__cta btn btn--optional">
          {stepNumber < (steps.length - 1) ? (
            <Link to={`/intro/${stepNumber + 1}`}>
              {content.nextButtonName}
            </Link>
          ) : (
            <Link to="/address-search">
              {content.nextButtonName}
            </Link>
          )}
        </div>
      </div>
    );
  }
}

IntroductionStep.propTypes = {
  steps: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired
};

export default withRouter(IntroductionStep);
