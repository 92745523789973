import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  Router,
  Switch,
  Route
} from 'react-router-dom';
import TagManager from 'react-gtm-module';
import {
  getQuestionsData,
  getSceneData,
  addAnswer,
  updateAnswer,
  setFigureAnimationIndexes,
  updateFigureAnimationIndexes,
  setMultipleFigureCount,
  updateMultipleFigureCount,
  resetMultipleFigureCount,
  deleteMultipleFigureCount,
  getTrackingDetails
} from '../actions/questions';
import {
  getIntroductionData,
  getLocationData,
  selectSuggestedLocation,
  lookupIp,
  lookupDomain,
  setUserLanguage
} from '../actions/introduction';
import {
  getTipsData,
  setTriggerScores,
  setIndicatorScores,
  setRecommendedCategories,
  addPlan
} from '../actions/tips';

import CookieCheck from './CookieCheck';
import LocationSearch from './LocationSearch';
import Header from './Header';
import Introduction from './Introduction';
import IntroductionStep from './IntroductionStep';
import QuestionsIntro from './QuestionsIntro';
import QuestionStep from './QuestionStep';
import Done from './Done';
import TipsIntro from './TipsIntro';
import Tips from './Tips';
import Plan from './Plan';
import RotationOverlay from './RotationOverlay';
import createBrowserHistory from 'history/createBrowserHistory';

class App extends Component {
  constructor (props) {
    super(props)

    this.history = createBrowserHistory()
  }

  componentDidMount () {
    const {
      getTrackingDetails,
      lookupDomain
      // lookupIp
    } = this.props;
    // Generates a random integer number between 10000000 and 99999999.
    const randomNumber = Math.floor(Math.random() * (99999999 - 10000000 + 1)) + 10000000;
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const expireDate = new Date(currentDate.setDate(currentDate.getDate() + 365));

    document.cookie = `TheIndoorGenerationTool=TIGTool-${year}/${month}/${day}-${randomNumber}; expires="${expireDate}";`;

    this.historyUnlisten = this.history.listen((location) => {
      TagManager.dataLayer({
        dataLayerName: 'PageDataLayer',
        dataLayer: {
          event: 'virtualPageView',
          virtualPage: location.pathname,
          pageTitle: 'Velux activation tool'
        }
      });
    });

    getTrackingDetails();
    // lookupIp();
    lookupDomain();
  }

  componentWillUnmount () {
    this.historyUnlisten();
  }

  render () {
    const {
      introduction,
      loadingLookup,
      loadingIntroduction,
      getLocation,
      loadingLocations,
      locations,
      selectSuggestedLocation,
      getIntroduction,
      getQuestions,
      loadingQuestions,
      updateQustionCounter,
      currentQuestionCount,
      totalQuestions,
      questions,
      getScene,
      sceneData,
      loadingScene,
      selectedLocation,
      done,
      tips,
      plan,
      loadingTips,
      getTips,
      answers,
      addAnswer,
      updateAnswer,
      triggerScores,
      setTriggerScores,
      indicatorScores,
      setIndicatorScores,
      triggers,
      indicators,
      recommendedCategories,
      setRecommendedCategories,
      setFigureAnimationIndexes,
      updateFigureAnimationIndexes,
      figureIndexes,
      addPlan,
      addedPlans,
      multipleFigureCount,
      setMultipleFigureCount,
      updateMultipleFigureCount,
      resetMultipleFigureCount,
      deleteMultipleFigureCount,
      ipData,
      languageSelection,
      userLanguage,
      setUserLanguage,
      trackingDetails
    } = this.props;

    return (
      loadingLookup ? (
        <div>loading...</div>
      ) : (
        <Router history={this.history}>
          <div>
            <Header
              ipData={ipData}
              languageSelection={languageSelection}
              setUserLanguage={setUserLanguage}
            />
            <Switch>
              <Route
                exact path="/"
                render={() =>
                  <Introduction
                    content={introduction}
                    loadingIntroduction={loadingIntroduction}
                    getIntroduction={getIntroduction}
                    userLanguage={userLanguage}
                  />
                }
              />
              <Route
                path="/intro/:step"
                render={(props) =>
                  <IntroductionStep
                    {...props}
                    steps={introduction.steps}
                    content={introduction}
                  />
                }
              />
              <Route
                path="/address-search"
                render={() =>
                  <LocationSearch
                    content={introduction.addressSearch}
                    getLocation={getLocation}
                    loadingLocations={loadingLocations}
                    locations={locations}
                    selectSuggestedLocation={selectSuggestedLocation}
                    selectedLocation={selectedLocation}
                  />
                }
              />
              <Route
                path="/:category/intro"
                render={(props) =>
                  <CookieCheck>
                    <QuestionsIntro
                      {...props}
                      content={questions}
                      getQuestions={getQuestions}
                      userLanguage={userLanguage}
                      loadingQuestions={loadingQuestions}
                    />
                  </CookieCheck>
                }
              />
              <Route
                path="/:category/:step"
                render={(props) =>
                  <CookieCheck>
                    <QuestionStep
                      {...props}
                      steps={questions.steps}
                      totalQuestions={totalQuestions}
                      currentQuestionCount={currentQuestionCount}
                      updateQustionCounter={updateQustionCounter}
                      questions={questions}
                      getScene={getScene}
                      sceneData={sceneData}
                      loadingScene={loadingScene}
                      answers={answers}
                      addAnswer={addAnswer}
                      updateAnswer={updateAnswer}
                      figureIndexes={figureIndexes}
                      setFigureAnimationIndexes={setFigureAnimationIndexes}
                      updateFigureAnimationIndexes={updateFigureAnimationIndexes}
                      setMultipleFigureCount={setMultipleFigureCount}
                      updateMultipleFigureCount={updateMultipleFigureCount}
                      multipleFigureCount={multipleFigureCount}
                      resetMultipleFigureCount={resetMultipleFigureCount}
                      deleteMultipleFigureCount={deleteMultipleFigureCount}
                      trackingDetails={trackingDetails}
                    />
                  </CookieCheck>
                }
              />
              <Route
                path="/done"
                render={() =>
                  <CookieCheck>
                    <Done
                      getTips={getTips}
                      content={done}
                      loadingTips={loadingTips}
                      indicators={indicators}
                      setIndicatorScores={setIndicatorScores}
                      triggers={triggers}
                      setTriggerScores={setTriggerScores}
                      userLanguage={userLanguage}
                    />
                  </CookieCheck>
                }
              />
              <Route
                path="/tips-intro"
                render={() =>
                  <CookieCheck>
                    <TipsIntro
                      content={tips}
                      triggerScores={triggerScores}
                      indicatorScores={indicatorScores}
                      recommendedCategories={recommendedCategories}
                      setRecommendedCategories={setRecommendedCategories}
                    />
                  </CookieCheck>
                }
              />
              <Route
                path="/tips"
                render={() =>
                  <CookieCheck>
                    <Tips
                      addPlan={addPlan}
                      content={tips}
                      recommendedCategories={recommendedCategories}
                    />
                  </CookieCheck>
                }
              />
              <Route
                path="/plan"
                render={() =>
                  <CookieCheck>
                    <Plan
                      content={plan}
                      addedPlans={addedPlans}
                    />
                  </CookieCheck>
                }
              />
              {
                // Route for all 404 errors
              }
              <Route
                render={() =>
                  <Introduction
                    content={introduction}
                    loadingIntroduction={loadingIntroduction}
                    getIntroduction={getIntroduction}
                    userLanguage={userLanguage}
                  />
                }
              />
            </Switch>
            <RotationOverlay />
          </div>
        </Router>
      )
    );
  }
}

const mapStateToProps = state => ({
  introduction: state.introductionReducer.introduction,
  loadingIntroduction: state.introductionReducer.loadingIntroduction,
  loadingLookup: state.introductionReducer.loadingLookup,
  loadingLocations: state.introductionReducer.loadingLocations,
  locations: state.introductionReducer.locations,
  questions: state.questionsReducer.questions,
  currentQuestionCount: state.questionsReducer.currentQuestionCount,
  totalQuestions: state.questionsReducer.totalQuestions,
  loadingQuestions: state.questionsReducer.loadingQuestions,
  sceneData: state.questionsReducer.sceneData,
  loadingScene: state.questionsReducer.loadingScene,
  selectedLocation: state.introductionReducer.selectedLocation,
  done: state.tipsReducer.done,
  tips: state.tipsReducer.tips,
  plan: state.tipsReducer.plan,
  loadingTips: state.tipsReducer.loadingTips,
  answers: state.questionsReducer.answers,
  triggers: state.tipsReducer.triggers,
  indicators: state.tipsReducer.indicators,
  triggerScores: state.tipsReducer.triggerScores,
  indicatorScores: state.tipsReducer.indicatorScores,
  recommendedCategories: state.tipsReducer.recommendedCategories,
  figureIndexes: state.questionsReducer.figureIndexes,
  addedPlans: state.tipsReducer.addedPlans,
  multipleFigureCount: state.questionsReducer.multipleFigureCount,
  ipData: state.introductionReducer.ipData,
  languageSelection: state.introductionReducer.languageSelection,
  userLanguage: state.introductionReducer.userLanguage,
  trackingDetails: state.questionsReducer.trackingDetails
});

const mapDispatchToProps = dispatch => ({
  getIntroduction: language => dispatch(getIntroductionData(language)),
  getLocation: input => dispatch(getLocationData(input)),
  selectSuggestedLocation: location => dispatch(selectSuggestedLocation(location)),
  getQuestions: (category, language) => dispatch(getQuestionsData(category, language)),
  getScene: scene => dispatch(getSceneData(scene)),
  getTips: language => dispatch(getTipsData(language)),
  addAnswer: (key, score, count) => dispatch(addAnswer(key, score, count)),
  updateAnswer: (key, score, count) => dispatch(updateAnswer(key, score, count)),
  setTriggerScores: (id, categoryName, score) => dispatch(setTriggerScores(id, categoryName, score)),
  setIndicatorScores: (id, categoryName, score) => dispatch(setIndicatorScores(id, categoryName, score)),
  setRecommendedCategories: (category, score) => dispatch(setRecommendedCategories(category, score)),
  setFigureAnimationIndexes: indexes => dispatch(setFigureAnimationIndexes(indexes)),
  updateFigureAnimationIndexes: indexes => dispatch(updateFigureAnimationIndexes(indexes)),
  addPlan: (suggestion, index) => dispatch(addPlan(suggestion, index)),
  setMultipleFigureCount: figure => dispatch(setMultipleFigureCount(figure)),
  updateMultipleFigureCount: (count, index) => dispatch(updateMultipleFigureCount(count, index)),
  resetMultipleFigureCount: () => dispatch(resetMultipleFigureCount()),
  deleteMultipleFigureCount: () => dispatch(deleteMultipleFigureCount()),
  lookupIp: () => dispatch(lookupIp()),
  lookupDomain: () => dispatch(lookupDomain()),
  setUserLanguage: language => dispatch(setUserLanguage(language)),
  getTrackingDetails: () => dispatch(getTrackingDetails())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
