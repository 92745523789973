// IE11 fix for the unsupported fetch API.
import promise from 'es6-promise';
import 'isomorphic-fetch';
promise.polyfill();

export const GET_TIPS_BEGIN = 'GET_TIPS_BEGIN';
export const GET_TIPS_SUCCESS = 'GET_TIPS_SUCCESS';
export const GET_TIPS_FAILURE = 'GET_TIPS_FAILURE';
export const SET_TRIGGER_SCORES = 'SET_TRIGGER_SCORES';
export const SET_INDICATOR_SCORES = 'SET_INDICATOR_SCORES';
export const SET_RECOMMENDED_CATEGORIES = 'SET_RECOMMENDED_CATEGORIES';
export const ADD_PLAN = 'ADD_PLAN';

export const getTipsData = language => {
  return dispatch => {
    dispatch(getTipsBegin());
    return fetch(`/resources/${language}/tips-plan.json`)
      .then(response => response.json())
      .then(json => {
        dispatch(getTipsSuccess(json));
      }, error => {
        dispatch(getTipsFailure(error));
      });
  };
};

export const getTipsBegin = () => {
  return {
    type: GET_TIPS_BEGIN
  };
};

export const getTipsSuccess = data => {
  return {
    type: GET_TIPS_SUCCESS,
    data
  };
};

export const getTipsFailure = error => {
  return {
    type: GET_TIPS_FAILURE,
    error
  };
};

export const setTriggerScores = (id, total, score) => {
  return {
    type: SET_TRIGGER_SCORES,
    id,
    total,
    score
  };
};

export const setIndicatorScores = (id, total, score) => {
  return {
    type: SET_INDICATOR_SCORES,
    id,
    total,
    score
  };
};

export const setRecommendedCategories = categories => {
  return {
    type: SET_RECOMMENDED_CATEGORIES,
    categories
  };
};

export const addPlan = (suggestion, index) => {
  return {
    type: ADD_PLAN,
    suggestion,
    index
  };
};