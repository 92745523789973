import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Bodymovin from 'bodymovin';
import cx from 'classnames';

class Scene extends Component {

  componentDidMount () {
    this.loadSceneAnimation();
  }

  loadSceneAnimation () {
    const {
      sceneData
    } = this.props;

    setTimeout(() => {
      Bodymovin.loadAnimation({
        container: document.querySelector('.scene__graphics'),
        renderer: 'svg',
        loop: false,
        autoplay: true,
        rendererSettings: {
          className: 'stage-background-svg'
        },
        animationData: sceneData
      });

      /* Bugfix for bodymovin module: Remove the duplicated
        svg element from the scene container, if bodymovin
        haven't properly removed it. */
      const sceneElements = document.getElementsByClassName('stage-background-svg');
      if (sceneElements.length > 1) {
        sceneElements[0].parentNode.removeChild(sceneElements[0]);
      }
    }, 500);
  }

  render () {
    const {
      figureIndexes,
      multipleFigureCount
    } = this.props;

    return (
      <div className="scene__background">
        <div className="scene__container">
          <div className="scene__graphics">
            {figureIndexes &&
              figureIndexes.map(figureIndex =>
                <div
                  key={figureIndex}
                  className={`animated fadeIn figure figure-${figureIndex + 1}`}
                />
              )
            }
            {multipleFigureCount.map((figure, index) =>
              <div
                key={index}
                className={cx('figure', `figure-${index + 1}`, {
                  'animated fadeIn': multipleFigureCount[index].count > 0,
                  'hidden': multipleFigureCount[index].count === 0
                })}>
                <p className={cx('figure-counter', `figure-counter-${index + 1}`)}>
                  {multipleFigureCount[index].count}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Scene.propTypes = {
  sceneData: PropTypes.object.isRequired,
  figureIndexes: PropTypes.array.isRequired,
  multipleFigureCount: PropTypes.array.isRequired
};

export default Scene;
