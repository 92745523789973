import {
  GET_LOCATION_BEGIN,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_FAILURE,
  GET_INTRODUCTION_BEGIN,
  GET_INTRODUCTION_SUCCESS,
  GET_INTRODUCTION_FAILURE,
  SELECT_SUGGESTED_LOCATION,
  LOOKUP_IP_BEGIN,
  LOOKUP_IP_SUCCESS,
  LOOKUP_IP_FAILURE,
  LOOKUP_DOMAIN_SUCCESS,
  SET_USER_LANGUAGE
} from '../actions/introduction';

const initialState = {
  loadingLocations: false,
  loadingIntroduction: false,
  loadingLookup: true,
  locations: [],
  selectedLocation: {},
  introduction: {},
  ipData: {},
  userLanguage: '',
  languageSelection: {
    'Belgium': [
      {
        native: 'Nederlands',
        name: 'belgium-dutch'
      },
      {
        native: 'Français',
        name: 'belgium-french'
      }
    ],
    'Switzerland': [
      {
        native: 'Deutsch',
        name: 'switzerland-german'
      },
      {
        native: 'Français',
        name: 'switzerland-french'
      },      
      {
        native: 'Italiano',
        name: 'switzerland-italian'
      }
    ]
  },
  error: null
};

export default function introductionReducer (state = initialState, action) {
  switch(action.type) {

  case GET_LOCATION_BEGIN:
    return {
      ...state,
      loadingLocations: true
    };

  case GET_LOCATION_SUCCESS:
    return {
      ...state,
      loadingLocations: false,
      locations: action.data.results
    };

  case GET_LOCATION_FAILURE:
    return {
      ...state,
      loadingLocations: false,
      error: action.error
    };

  case GET_INTRODUCTION_BEGIN:
    return {
      ...state,
      loadingIntroduction: true,
    };

  case GET_INTRODUCTION_SUCCESS:
    return {
      ...state,
      loadingIntroduction: false,
      introduction: action.data.introduction
    };

  case GET_INTRODUCTION_FAILURE:
    return {
      ...state,
      loadingIntroduction: false,
      error: action.error
    };

  case SELECT_SUGGESTED_LOCATION:
    return {
      ...state,
      selectedLocation: state.locations[action.index]
    };

  case LOOKUP_IP_BEGIN:
    return {
      ...state,
      loadingLookup: true
    };

  case LOOKUP_IP_SUCCESS:
    return {
      ...state,
      loadingLookup: false,
      ipData: action.data
    };

  case LOOKUP_IP_FAILURE:
    return {
      ...state,
      loadingLookup: false,
      error: action.error
    };

  case LOOKUP_DOMAIN_SUCCESS:
    return {
      ...state,
      loadingLookup: false,
      ipData: action.data
    };

  case SET_USER_LANGUAGE:
    return {
      ...state,
      userLanguage: action.language
    }

  default:
    return state;
  }
}
