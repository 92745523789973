// IE11 fix for the unsupported fetch API.
import promise from 'es6-promise';
import 'isomorphic-fetch';
promise.polyfill();

export const GET_QUESTIONS_BEGIN = 'GET_QUESTIONS_BEGIN';
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';
export const GET_QUESTIONS_FAILURE = 'GET_QUESTIONS_FAILURE';
export const GET_SCENE_BEGIN = 'GET_SCENE_BEGIN';
export const GET_SCENE_SUCCESS = 'GET_SCENE_SUCCESS';
export const GET_SCENE_FAILURE = 'GET_SCENE_FAILURE';
export const ADD_ANSWER = 'ADD_ANSWER';
export const UPDATE_ANSWER = 'UPDATE_ANSWER';
export const SET_FIGURE_ANIMATION_INDEXES = 'SET_FIGURE_ANIMATION_INDEXES';
export const UPDATE_FIGURE_ANIMATION_INDEXES = 'UPDATE_FIGURE_ANIMATION_INDEXES';
export const SET_MULTIPLE_FIGURE_COUNT = 'SET_MULTIPLE_FIGURE_COUNT';
export const UPDATE_MULTIPLE_FIGURE_COUNT = 'UPDATE_MULTIPLE_FIGURE_COUNT';
export const RESET_MULTIPLE_FIGURE_COUNT = 'RESET_MULTIPLE_FIGURE_COUNT';
export const DELETE_MULTIPLE_FIGURE_COUNT = 'DELETE_MULTIPLE_FIGURE_COUNT';
export const GET_TRACKING_DETAILS_BEGIN = 'GET_TRACKING_DETAILS_BEGIN';
export const GET_TRACKING_DETAILS_SUCCESS = 'GET_TRACKING_DETAILS_SUCCESS';
export const GET_TRACKING_DETAILS_FAILURE = 'GET_TRACKING_DETAILS_FAILURE';

export const getQuestionsData = (category, language) => {
  return dispatch => {
    dispatch(getQuestionsBegin());
    return fetch(`/resources/${language}/${category}.json`)
      .then(response => response.json())
      .then(json => {
        dispatch(getQuestionsSuccess(json));
      }, error => {
        dispatch(getQuestionsFailure(error));
      });
  };
};

export const getSceneData = scene => {
  return dispatch => {
    dispatch(getSceneBegin());
    return fetch(`/scenes/${scene}.json`)
      .then(response => response.json())
      .then(json => {
        dispatch(getSceneSuccess(json));
      }, error => {
        dispatch(getSceneFailure(error));
      });
  };
};

export const getTrackingDetails = () => {
  return dispatch => {
    dispatch(getTrackingDetailsBegin());
    return fetch('/resources/questions-tracking.json')
      .then(response => response.json())
      .then(json => {
        dispatch(getTrackingDetailsSuccess(json));
      }, error => {
        dispatch(getTrackingDetailsFailure(error));
      });
  };
};

export const getQuestionsBegin = () => {
  return {
    type: GET_QUESTIONS_BEGIN
  };
};

export const getQuestionsSuccess = data => {
  return {
    type: GET_QUESTIONS_SUCCESS,
    data
  };
};

export const getQuestionsFailure = error => {
  return {
    type: GET_QUESTIONS_FAILURE,
    error
  };
};

export const getSceneBegin = () => {
  return {
    type: GET_SCENE_BEGIN,
  };
};

export const getSceneSuccess = data => {
  return {
    type: GET_SCENE_SUCCESS,
    data
  };
};

export const getSceneFailure = error => {
  return {
    type: GET_SCENE_FAILURE,
    error
  };
};

export const addAnswer = (key, score, count) => {
  return {
    type: ADD_ANSWER,
    key,
    score,
    count
  };
};

export const updateAnswer = (key, score, count) => {
  return {
    type: UPDATE_ANSWER,
    key,
    score,
    count
  };
};

export const setFigureAnimationIndexes = indexes => {
  return {
    type: SET_FIGURE_ANIMATION_INDEXES,
    indexes
  };
};

export const updateFigureAnimationIndexes = indexes => {
  return {
    type: UPDATE_FIGURE_ANIMATION_INDEXES,
    indexes
  };
};

export const setMultipleFigureCount = figure => {
  return {
    type: SET_MULTIPLE_FIGURE_COUNT,
    figure
  };
};

export const updateMultipleFigureCount = (count, index) => {
  return {
    type: UPDATE_MULTIPLE_FIGURE_COUNT,
    count,
    index
  };
};

export const resetMultipleFigureCount = () => {
  return {
    type: RESET_MULTIPLE_FIGURE_COUNT
  };
};

export const deleteMultipleFigureCount = () => {
  return {
    type: DELETE_MULTIPLE_FIGURE_COUNT
  };
};

export const getTrackingDetailsBegin = () => {
  return {
    type: GET_TRACKING_DETAILS_BEGIN
  };
};

export const getTrackingDetailsSuccess = data => {
  return {
    type: GET_TRACKING_DETAILS_SUCCESS,
    data
  };
};

export const getTrackingDetailsFailure = error => {
  return {
    type: GET_TRACKING_DETAILS_FAILURE,
    error
  }
}