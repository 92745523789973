import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  Link,
  Redirect
} from 'react-router-dom';
import cx from 'classnames';
import TagManager from 'react-gtm-module';

import pinpoint from '../../images/icon-pin.svg';
import Overlay from './Overlay';

class LocationSearch extends Component {
  constructor () {
    super();
    this.state = {
      locationInput: '',
      hasInputChanged: false,
      isLocationSelected: false,
      showOverlay: false
    };
    this.searchForLocation = this.searchForLocation.bind(this);
    this.requestLocation = this.requestLocation.bind(this);
  }

  componentDidUpdate (prevProps) {
    const {selectedLocation} = this.props;

    if (prevProps.selectedLocation !== selectedLocation) {
      const currentDate = new Date();
      const expireDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
      const sunrise = selectedLocation.annotations.sun.rise.civil;
      const sunset = selectedLocation.annotations.sun.set.civil;
      const sunlightInMinutes = (sunset - sunrise) / 60;

      document.cookie = `sunlightInMinutes=${sunlightInMinutes}; expires="${expireDate}";`;
    }
  }

  componentWillUnmount () {
    this.setState({
      locationInput: '',
      hasInputChanged: false
    });
  }

  searchForLocation (event) {
    clearInterval(this.interval);
    this.startTimer();
    this.setState({
      locationInput: event.target.value
    });
  }

  startTimer () {
    this.interval = setInterval(this.requestLocation, 500);
  }

  requestLocation () {
    const {locationInput} = this.state;
    const {getLocation} = this.props;

    this.setState({
      hasInputChanged: true,
      isLocationSelected: false
    });
    getLocation(locationInput);
    clearInterval(this.interval);
  }

  selectLocation (index) {
    const {
      locations,
      selectSuggestedLocation
    } = this.props;

    TagManager.dataLayer({
      dataLayerName: 'PageDataLayer',
      dataLayer: {
        'event': 'interactionevent',
        'eventcategory': 'QuestionAnswer',
        'eventaction': 'location',
        'eventlabel': 'location'
      }
    });

    this.setState({
      locationInput: locations[index].formatted,
      isLocationSelected: true
    });
    selectSuggestedLocation(index);
  }

  togglePopup () {
    const {showOverlay} = this.state;

    this.setState({
      showOverlay: !showOverlay
    });
  }

  render () {
    const {
      locationInput,
      hasInputChanged,
      isLocationSelected,
      showOverlay
    } = this.state;

    const {
      content,
      locations,
      loadingLocations
    } = this.props;

    if (!content) {
      return <Redirect to="/" />;
    }

    return (
      <div className="location-search">

        <button
          onClick={() => this.togglePopup()}
          className={cx('question__tip', {
            'question__tip--active' : showOverlay
          })}>
          <span className="sr-only">
            {content.whyWeAsk.title}
          </span>
        </button>

        <div className="location-search__image-wrapper">
          <img src={pinpoint} alt="hero image" />
        </div>

        <p className="location-search__title">
          <strong>{content.title}</strong>
        </p>
        <p className="location-search__copy">{content.description}</p>
        <input
          type="text"
          onChange={this.searchForLocation}
          value={locationInput}
          placeholder={content.inputPlaceholder}
          className="address-search" />
        {loadingLocations ? (
          <div>...</div>
        ) : [
          <ul
            key="address-serach-dropdown"
            className={cx('address-search__dropdown', {
              'hidden': locations.length === 0 || isLocationSelected
            })}>
            {locations.map((location, index) =>
              <li key={index} className="address-search__item">
                <button onClick={() => this.selectLocation(index)} className="address-search__button">
                  {location.formatted}
                </button>
              </li>
            )}
          </ul>,
          hasInputChanged && locations.length === 0 && locationInput.length > 0 &&
            <div className="address-search__error" key="address-not-found">
              Address not found.
            </div>
        ]}
        {isLocationSelected ? (
          <div className="location-search__cta btn btn--optional">
            <Link to="/your-home/intro">
              {content.startButtonName}
            </Link>
          </div>
        ) : (
          <button className="location-search__cta btn btn--optional" disabled={true}>
            {content.startButtonName}
          </button>
        )}

        {showOverlay &&
          <Overlay>
            <div className="overlay__inner">
              <div className="overlay__text-container">
                <h3>{content.whyWeAsk.title}</h3>
                <p>{content.whyWeAsk.description}</p>
                {content.whyWeAsk.tipsTitle &&
                  <p>
                    <strong>{content.whyWeAsk.tipsTitle}</strong>
                  </p>
                }
                {content.whyWeAsk.tipsText &&
                  <p>{content.whyWeAsk.tipsText}</p>
                }
              </div>
              <button className="btn" onClick={() => this.togglePopup()}>
                {content.whyWeAsk.closeButtonText}
              </button>
            </div>
          </Overlay>
        }
      </div>
    );
  }
}

LocationSearch.propTypes = {
  content: PropTypes.object.isRequired,
  getLocation: PropTypes.func.isRequired,
  loadingLocations: PropTypes.bool.isRequired,
  locations: PropTypes.array.isRequired,
  selectSuggestedLocation: PropTypes.func.isRequired,
  selectedLocation: PropTypes.object.isRequired
};

export default LocationSearch;
