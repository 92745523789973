// IE11 fix for the unsupported fetch API.
import promise from 'es6-promise';
import 'isomorphic-fetch';
promise.polyfill();

export const GET_LOCATION_BEGIN = 'GET_LOCATION_BEGIN';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_FAILURE = 'GET_LOCATION_FAILURE';
export const GET_INTRODUCTION_BEGIN = 'GET_INTRODUCTION_BEGIN';
export const GET_INTRODUCTION_SUCCESS = 'GET_INTRODUCTION_SUCCESS';
export const GET_INTRODUCTION_FAILURE = 'GET_INTRODUCTION_FAILURE';
export const SELECT_SUGGESTED_LOCATION = 'SELECT_SUGGESTED_LOCATION';
export const LOOKUP_IP_BEGIN = 'LOOKUP_IP_BEGIN';
export const LOOKUP_IP_SUCCESS = 'LOOKUP_IP_SUCCESS';
export const LOOKUP_IP_FAILURE = 'LOOKUP_IP_FAILURE';
export const LOOKUP_DOMAIN_SUCCESS = 'LOOKUP_DOMAIN_SUCCESS';
export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';

export const getIntroductionData = language => {
  return dispatch => {
    dispatch(getIntroductionBegin());
    return fetch(`/resources/${language}/introduction.json`)
      .then(response => response.json())
      .then(json => {
        dispatch(getIntroductionSuccess(json));
      }, error => {
        dispatch(getIntroductionFailure(error));
      });
  };
};

export const getLocationData = input => {
  const apiKey = 'e6dcdb6c46a9403e824036389338d6a4';
  return dispatch => {
    dispatch(getLocationBegin());
    return fetch(`https://api.opencagedata.com/geocode/v1/json?key=${apiKey}&q=${input}&min_confidence=3&no_record=1`)
      .then(response => response.json())
      .then(json => {
        dispatch(getLocationSuccess(json));
      }, error => {
        dispatch(getLocationFailure(error));
      });
  };
};

export const lookupIp = () => {
  const apiKey = '95c272d4260921ab13d1e6893c0876cb';
  return dispatch => {
    dispatch(lookupIpBegin());
    return fetch(`https://api.ipstack.com/check?access_key=${apiKey}`)
      .then(response => response.json())
      .then(json => {
        dispatch(getUserLanguage(json))
        dispatch(lookupIpSuccess(json));
      }, error => {
        dispatch(lookupIpFailure(error));
      });
  };
};

export const lookupDomain = () => {
  return dispatch => {
    const tld = window.location.hostname.substring(window.location.hostname.lastIndexOf('.') + 1);
    const data = {
      country_name: 'UK'
    };
    switch (tld) {
    case 'at': data.country_name = 'Austria'; break;
    case 'be': data.country_name = 'Belgium'; break;
    case 'ch': data.country_name = 'Switzerland'; break;
    case 'fr': data.country_name = 'France'; break;
    case 'de': data.country_name = 'Germany'; break;
    case 'dk': data.country_name = 'Denmark'; break;
    case 'es': data.country_name = 'Spain'; break;
    case 'nl': data.country_name = 'Netherlands'; break;
    }
    dispatch(lookupDomainSuccess(data));
    dispatch(getUserLanguage(data));
  };
};

export const getUserLanguage = data => {
  return dispatch => {
    switch (data.country_name) {
    case 'Austria':
      return dispatch(setUserLanguage('austrian'));
    case 'Belgium':
      return dispatch(setUserLanguage('belgium-dutch'));
    case 'Switzerland':
      return dispatch(setUserLanguage('switzerland-german'));
    case 'Germany':
      return dispatch(setUserLanguage('german'));
    case 'France':
      return dispatch(setUserLanguage('french'));
    case 'Netherlands':
      return dispatch(setUserLanguage('dutch'));
    case 'Denmark':
      return dispatch(setUserLanguage('danish'));
    case 'Spain':
      return dispatch(setUserLanguage('spanish'));
    default:
      return dispatch(setUserLanguage('english'));
    }
  };
};

export const getIntroductionBegin = () => {
  return {
    type: GET_INTRODUCTION_BEGIN
  };
};

export const getIntroductionSuccess = data => {
  return {
    type: GET_INTRODUCTION_SUCCESS,
    data
  };
};

export const getIntroductionFailure = error => {
  return {
    type: GET_INTRODUCTION_FAILURE,
    error
  };
};

export const getLocationBegin = () => {
  return {
    type: GET_LOCATION_BEGIN
  };
};

export const getLocationSuccess = data => {
  return {
    type: GET_LOCATION_SUCCESS,
    data
  };
};

export const getLocationFailure = error => {
  return {
    type: GET_LOCATION_FAILURE,
    error
  };
};

export const selectSuggestedLocation = index => {
  return {
    type: SELECT_SUGGESTED_LOCATION,
    index
  };
};

export const lookupIpBegin = () => {
  return {
    type: LOOKUP_IP_BEGIN
  };
};

export const lookupIpSuccess = data => {
  return {
    type: LOOKUP_IP_SUCCESS,
    data
  };
};

export const lookupIpFailure = error => {
  return {
    type: LOOKUP_IP_FAILURE,
    error
  };
};

export const lookupDomainSuccess = data => {
  return {
    type: LOOKUP_DOMAIN_SUCCESS,
    data
  };
};

export const setUserLanguage = language => {
  return {
    type: SET_USER_LANGUAGE,
    language
  };
};