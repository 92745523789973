import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  Link,
  Redirect
} from 'react-router-dom';
import _ from 'lodash';

class TipsIntro extends Component {

  componentDidMount () {
    const {
      content,
      triggerScores,
      indicatorScores,
      setRecommendedCategories
    } = this.props;
    let scoreCategoriesSorted = [];

    if (Object.keys(content).length === 0) {
      return <Redirect to="/" />;
    }

    this.sortHighestPriority(triggerScores).map(category => {
      scoreCategoriesSorted.push(category);
    });
    this.sortHighestPriority(indicatorScores).map(category => {
      scoreCategoriesSorted.push(category);
    });

    setRecommendedCategories(this.reduceDuplicates(scoreCategoriesSorted));
  }

  sortHighestPriority (scores) {
    const {content} = this.props;
    let categories = [];

    content.categories.map(category => {
      const categoryScore = this.retrieveCategoryScore(scores, category.id);

      if (categoryScore > 0) {
        categories.push({
          category: category,
          score: categoryScore,
          priority: category.priority
        });
      }
    });

    // Sort categories after highest priority.
    categories.sort((a, b) => {
      return b.priority - a.priority;
    });
    return categories;
  }

  reduceDuplicates (categories) {
    let sorted = categories.reduce((tempResult, category) => {
      // Filter categories with same priority number.
      let result = _.find(tempResult, item =>
        item.priority == category.priority
      );

      if (!result) {
        return tempResult.concat(category);
      }
      return tempResult
    }, []);

    // Return top 3 categories.
    return sorted.splice(0, 3);
  }

  retrieveCategoryScore (keys, categoryId) {
    let score = 0;

    keys.map(key => {
      if (key.categoryId === categoryId) {
        score = key.score;
      }
    })
    return score;
  }

  showRecommendedCategories (categories) {
    return categories.map((category, index) =>
      <div className="tip-category" key={index}>
        <img
          alt={category.title}
          src={this.getCategoryIcon(category.title)}
          className="tip-category__img"
        />
        <p className="small tip-category__title">
          {category.title}
        </p>
      </div>
    );
  }

  render () {
    const {
      content,
      recommendedCategories
    } = this.props;

    if (Object.keys(content).length === 0) {
      return <Redirect to="/" />;
    }

    return (
      <div className="tips-intro">
        <div className="tips-intro__inner">
          <h2 className="tips-intro__title">{content.headline}</h2>

          <div className="tips-categories">
            {recommendedCategories.map((content, index) =>
              <div className="tip-category" key={index}>
                <div className="tip-category__icon-container">
                  <img
                    alt={content.category.title}
                    src={content.category.icon}
                    className="tip-category__img"
                  />
                </div>
                <p className="small tip-category__title">
                  {content.category.title}
                </p>
              </div>
            )}
          </div>
          <div className="tips-intro__btn btn">
            <Link to="/tips">
              {content.ctaButtonName}
            </Link>
          </div>

          <p className="small tips-intro__instructions">
            {content.explanation}
          </p>
        </div>
      </div>
    );
  }
}

TipsIntro.propTypes = {
  content: PropTypes.object.isRequired,
  triggerScores: PropTypes.array.isRequired,
  indicatorScores: PropTypes.array.isRequired,
  setRecommendedCategories: PropTypes.func.isRequired,
  recommendedCategories: PropTypes.array.isRequired
};

export default TipsIntro;
