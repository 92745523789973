import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  Redirect,
  withRouter
} from 'react-router-dom';
import TagManager from 'react-gtm-module';

import Overlay from './Overlay';

class Tips extends Component {
  constructor () {
    super();
    this.state = {
      categoryIndex: 0,
      activeButton: -1,
      showOverlay: false
    }
  }

  componentDidMount () {
    this.submitPresentedTips();

    TagManager.dataLayer({
      dataLayerName: 'PageDataLayer',
      dataLayer: {
        'event': 'interactionevent',
        'eventcategory': 'QuestionAnswer',
        'eventaction': 'SendResult',
        'eventlabel': 'TipsIntro',
        'eventvalue':  null
      }
    });
  }

  componentDidUpdate () {
    this.submitPresentedTips();
  }

  componentWillUnmount () {
    this.setState({
      categoryIndex: 0,
      activeButton: -1,
    });
  }

  submitPresentedTips () {
    const {categoryIndex} = this.state;
    const {recommendedCategories} = this.props;
    const categories = recommendedCategories[categoryIndex];
    let suggestions = '';

    if (recommendedCategories.length > 0) {
      categories.category.suggestions.map(suggestion => {
        suggestions += `${suggestion.title}, `;
      });

      TagManager.dataLayer({
        dataLayerName: 'PageDataLayer',
        dataLayer: {
          'event': 'interactionevent',
          'eventcategory': 'tips',
          'eventaction': 'presented_tips',
          'eventlabel': recommendedCategories[categoryIndex].category.title,
          'eventvalue': suggestions
        }
      });
    }
  }

  goToPlanOverview () {
    const {history} = this.props;
    history.push('/plan');
  }

  addToPlan (suggestion, index) {
    const {categoryIndex} = this.state;
    const {addPlan} = this.props;
    const maxCategoryIndex = 2;

    addPlan(suggestion, index);

    TagManager.dataLayer({
      dataLayerName: 'PageDataLayer',
      dataLayer: {
        'event': 'interactionevent',
        'eventcategory': 'tips',
        'eventaction': 'chosenTip',
        'eventlabel': index,
        'eventvalue': suggestion.title
      }
    });

    if (categoryIndex === maxCategoryIndex) {
      this.goToPlanOverview();
    } else {
      this.setState({
        categoryIndex: categoryIndex + 1,
        activeButton: index
      });
    }
  }

  togglePopup () {
    const {showOverlay} = this.state;

    this.setState({
      showOverlay: !showOverlay
    });
  }

  render () {
    const {
      categoryIndex,
      activeButton,
      showOverlay
    } = this.state;
    const {
      content,
      recommendedCategories
    } = this.props;
    const categories = recommendedCategories
      ? recommendedCategories[categoryIndex]
      : '';

    if (!categories) {
      return <Redirect to="/" />;
    }

    return (
      <div className="tips">

        <button
          onClick={() => this.togglePopup()}
          className={cx('question__tip', {
            'question__tip--active' : showOverlay
          })}>
          <span className="sr-only">
            {content.whyWeAskTitle}
          </span>
        </button>

        <div className="tips__header">
          <img
            alt="Checkmark"
            className="tips__header-img"
            src={categories.category.icon}
          />
          <h3 className="tips__header-title">
            {categories.category.title}
          </h3>
          <p className="small tips__header-copy">
            {categories.category.description}
          </p>
          <div>
            <ul className="tips__pagination">
              {recommendedCategories.map((step, index) =>
                <li
                  key={step.category.id}
                  className={cx('tips__pagination-circle', {
                    'tips__pagination-circle--active': categoryIndex === index
                  })}>
                  <span className="sr-only">
                    Step {categoryIndex + 1}
                  </span>
                </li>
              )}
            </ul>
          </div>
        </div>

        <div className="tips__tips">
          <div className="tips__inner">
            {categories.category.suggestions.map((suggestion, index) =>
              <div className="tips__tip" key={index}>
                <div className="tips__text-inner">
                  <p>
                    <strong>{suggestion.title}</strong>
                  </p>
                  <p className="tips__tip-copy">
                    {suggestion.description}
                  </p>
                </div>
                <button
                  className={cx('tips__tip-cta', {
                    'tips__tip-cta--nohover': activeButton === index
                  })}
                  onClick={() => this.addToPlan(suggestion, categoryIndex)}>
                  {content.addPlanButtonName}
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="container u-hide-mobile question__text--desktop">
          <p className="small">
            <strong>{content.whyWeAskTitle}</strong>
          </p>
          <p className="small">
            {categories.category.whyWeAskExcerpt}
          </p>
        </div>

        {showOverlay &&
          <Overlay>
            <div className="overlay__inner">
              <div className="overlay__text-container">
                <h3>{content.whyWeAskTitle}</h3>
                <p>{categories.category.whyWeAskExcerpt}</p>
              </div>
              <button className="btn" onClick={() => this.togglePopup()}>
                {content.whyWeAskCloseButton}
              </button>
            </div>
          </Overlay>
        }
      </div>
    );
  }
}

Tips.propTypes = {
  addPlan: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  recommendedCategories: PropTypes.array.isRequired,
  content: PropTypes.object.isRequired
}

export default withRouter(Tips);
