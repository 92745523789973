import {
  GET_TIPS_BEGIN,
  GET_TIPS_SUCCESS,
  GET_TIPS_FAILURE,
  SET_TRIGGER_SCORES,
  SET_INDICATOR_SCORES,
  SET_RECOMMENDED_CATEGORIES,
  ADD_PLAN
} from '../actions/tips';

const initialState = {
  loadingTips: false,
  done: {},
  tips: {},
  plan: {},
  triggers: [],
  indicators: [],
  triggerScores: [],
  indicatorScores: [],
  recommendedCategories: [],
  addedPlans: [{}, {}, {}],
  error: null
};

export default function tipsReducer (state = initialState, action) {
  switch(action.type) {

  case GET_TIPS_BEGIN:
    return {
      ...state,
      loadingTips: true
    };

  case GET_TIPS_SUCCESS:
    return {
      ...state,
      loadingTips: false,
      done: action.data.done,
      tips: action.data.tips,
      plan: action.data.plan,
      triggers: action.data.tips.categories.map(category => {
        return {
          ...state.triggers,
          categoryId: category.id,
          keys: category.triggers
        }
      }),
      indicators: action.data.tips.categories.map(category => {
        return {
          ...state.indicators,
          categoryId: category.id,
          keys: category.indicators
        }
      })
    };

  case GET_TIPS_FAILURE:
    return {
      ...state,
      loadingTips: false,
      error: action.error
    };

  case SET_TRIGGER_SCORES:
    return {
      ...state,
      triggerScores: [
        ...state.triggerScores,
        {
          categoryId: action.id,
          score: action.total 
            ? Math.round(action.score / action.total * 100)
            : 0
        }
      ]
    }

  case SET_INDICATOR_SCORES:
    return {
      ...state,
      indicatorScores: [
        ...state.indicatorScores,
        {
          categoryId: action.id,
          score: action.total 
            ? Math.round(action.score / action.total * 100)
            : 0
        }
      ]
    }

  case SET_RECOMMENDED_CATEGORIES:
    return {
      ...state,
      recommendedCategories: action.categories
    }

  case ADD_PLAN:
    return {
      ...state,
      addedPlans: state.addedPlans.map((plan, index) => {
        if (index === action.index) {
          return action.suggestion
        }
        return plan
      })
    }

  default:
    return state;
  }
}