import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import checkmark from '../../images/done-checkmark.svg';

class Done extends Component {

  componentDidMount () {
    const {
      history,
      getTips,
      userLanguage
    } = this.props;

    getTips(userLanguage);
    this.timer = setTimeout(() => {
      history.push('/tips-intro')
    }, 2000);
  }

  componentWillUnmount () {
    clearTimeout(this.timer);
  }

  componentDidUpdate (prevProps) {
    const {
      content,
      triggers,
      setTriggerScores,
      indicators,
      setIndicatorScores
    } = this.props;

    if (prevProps.content !== content) {
      triggers.map(trigger => {
        const score = this.cookieKeysMatch(trigger.keys);
        if (score > 0) {
          setTriggerScores(trigger.categoryId, trigger.keys.length, score);
        }
      });

      indicators.map(indicator => {
        const score = this.cookieKeysMatch(indicator.keys);
        if (score > 0) {
          setIndicatorScores(indicator.categoryId, indicator.keys.length, score);
        }
      });
    }
  }

  cookieKeysMatch (keys) {
    let categoryScore = 0;

    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      categoryScore = categoryScore + this.setScore(key.cookies, key.scores, key.checkForHighestScore, key.checkExactScore);
    }
    return categoryScore;
  }

  setScore(cookies, scores, checkHighestScore, exactScore) {
    let keyScores = 0; 

    for (let index = 0; index < cookies.length; index++) {
      const cookie = cookies[index];
      const acceptedScore = scores[index];
      const cookieScore = this.getCookie(cookie);

      if (exactScore) {
        if (cookieScore == acceptedScore) {
          keyScores++;
        }
      } else {
        if ((checkHighestScore && cookieScore >= acceptedScore) || (!checkHighestScore && cookieScore <= acceptedScore)) {
          keyScores++;
        }
      }
    }
    /* If the scores from the cookies matches 
      all of the accepted scores in a key array, 
      return with one score for the category. */
    if ((keyScores / cookies.length) === 1) {
      return 1;
    }
    return 0;
  }

  getCookie(name) {
    return document.cookie.split('; ').reduce((r, v) => {
      const parts = v.split('=')
      return parts[0] === name ? decodeURIComponent(parts[1]) : r
    }, '')
  }

  render () {
    const {
      content,
      loadingTips
    } = this.props;

    return (
      loadingTips ? (
        <div>loading...</div>
      ) : (
        <div className="done">
          <div className="done__inner">
            <img src={checkmark} alt="Checkmark" className="done__img" />
            <h2 className="done__title">{content.title}</h2>
            <p>{content.excerpt}</p>
          </div>
        </div>
      )
    );
  }
}

Done.propTypes = {
  loadingTips: PropTypes.bool.isRequired,
  content: PropTypes.object.isRequired,
  getTips: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  triggers: PropTypes.array.isRequired,
  setTriggerScores: PropTypes.func.isRequired,
  indicators: PropTypes.array.isRequired,
  setIndicatorScores: PropTypes.func.isRequired,
  userLanguage: PropTypes.string.isRequired
};

export default withRouter(Done);
