import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';

class CookieCheck extends Component {
  constructor () {
    super();
    this.state = {
      locationCookie: document.cookie.indexOf('sunlightInMinutes') > -1
    };
  }

  render () {
    const {locationCookie} = this.state;
    const {children} = this.props;

    if (!locationCookie) {
      return <Redirect to="/" />;
    }

    return (
      <div>
        {children}
      </div>
    );
  }
}

CookieCheck.propTypes = {
  children: PropTypes.node.isRequired
};

export default CookieCheck;