import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

class QuestionsIntro extends Component {
  constructor () {
    super();
    this.goToQuestionSteps = this.goToQuestionSteps.bind(this);
  }

  componentDidMount () {
    const {
      match,
      getQuestions,
      userLanguage
    } = this.props;

    getQuestions(match.params.category, userLanguage);
    this.timer = setTimeout(this.goToQuestionSteps, 2000);
  }

  componentWillUnmount () {
    clearTimeout(this.timer);
  }

  goToQuestionSteps () {
    const {
      match,
      history
    } = this.props;

    history.push(`/${match.params.category}/0`);
  }

  render () {
    const {
      content,
      loadingQuestions
    } = this.props;

    return (
      loadingQuestions ? (
        <div>loading...</div>
      ) : (
        <div className="scene-intro" style={
          {backgroundImage: content.heroImageUrl ? `url(${content.heroImageUrl})` : 'none'}
        }>
          <div className="container scene-intro__title">
            <h1 className="scene-intro__title--desktop">{content.heroTitle}</h1>
          </div>
        </div>
      )
    );
  }
}

QuestionsIntro.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  getQuestions: PropTypes.func.isRequired,
  loadingQuestions: PropTypes.bool.isRequired,
  userLanguage: PropTypes.string.isRequired
};

export default withRouter(QuestionsIntro);
