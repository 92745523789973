import {createStore, applyMiddleware, combineReducers} from 'redux';
import {createLogger} from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import introductionReducer from './reducers/introduction';
import questionsReducer from './reducers/questions';
import tipsReducer from './reducers/tips';

const devMode = process.env.NODE_ENV !== 'production';
const middlewares = [thunkMiddleware];

if (devMode) {
  middlewares.push(createLogger());
}

export default function configureStore (preloadedState) {
  return createStore(
    combineReducers({
      introductionReducer,
      questionsReducer,
      tipsReducer
    }),
    preloadedState,
    applyMiddleware(...middlewares)
  );
}
