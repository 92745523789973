import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

class Overlay extends Component {
  constructor() {
    super();
    this.overlayRoot = document.querySelector('.overlay-spawnpoint');
    this.childRoot = document.createElement('div');
  }

  componentDidMount() {
    this.overlayRoot.appendChild(this.childRoot);
  }

  componentWillUnmount() {
    this.overlayRoot.removeChild(this.childRoot);
  }

  render() {
    const {children} = this.props;

    return (
      ReactDOM.createPortal(
        <div className="overlay">
          {children}
        </div>,
        this.overlayRoot
      )
    );
  }
}

Overlay.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Overlay;
