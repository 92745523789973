import React, {Component} from 'react';

import phoneOrientationIllustration from '../../images/turn-phone-illustration.svg';

class RotationOverlay extends Component {
  render () {
    return (
      <div className="rotate-device-overlay">
        <img src={phoneOrientationIllustration} alt="Turn your phone to continue" />
        <p>Turn your phone to continue</p>
      </div>
    );
  }
}

export default RotationOverlay;
