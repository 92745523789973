import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import TagManager from 'react-gtm-module';
import configureStore from './configureStore';
import '../scss/app.scss';
import App from './components/App';
require('../fonts/VeluxGothic-Bold_WebTT.ttf');
require('../fonts/VeluxGothic-Regular_WebTT.ttf');

const store = configureStore();
const devMode = process.env.NODE_ENV !== 'production';

/* The ID we use in devlopment mode:'GTM-M87DVVW'. This tracking ID is made by Billy Maddocks.
   The ID we use in production mode: 'GTM-TSZK83X', which was replaced by 'GTM-MPJMTWG' */
TagManager.initialize({
  gtmId: devMode ? 'GTM-5CZFX4S' : 'GTM-MPJMTWG',
  dataLayerName: 'PageDataLayer',  
});
TagManager.dataLayer({
  dataLayerName: 'PageDataLayer',
  dataLayer: {
    originalLocation: 
      document.location.protocol + '//' +
      document.location.hostname +
      document.location.pathname +
      document.location.search
  }
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>
  , document.querySelector('main')
);
