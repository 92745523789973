import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import cx from 'classnames';

import veluxLogo from '../../images/VELUX-logo.svg';
import indoorGenWhite from '../../images/the-indoor-generation-white.svg';
import indoorGenBlack from '../../images/the-indoor-generation-black.svg';

class Header extends Component {
  constructor () {
    super();
    this.state = {
      languageButtonIndex: -1
    }
    this.selectLanguage = this.selectLanguage.bind(this);
  }

  selectLanguage (language, index) {
    const {
      history,
      setUserLanguage
    } = this.props;

    this.setState({
      languageButtonIndex: index
    });
    setUserLanguage(language);
    history.push('/');
  }

  buildLanguageSelection (hasCountryName) {
    const {languageButtonIndex} = this.state;
    const {
      ipData,
      languageSelection
    } = this.props;

    if (hasCountryName) {
      const languages = languageSelection[ipData.country_name];

      return languages.map((language, index) =>
        <li key={index}>
          <button
            className={cx('languages__item', {
              'languages__item--active': index === languageButtonIndex
            })}
            onClick={() => this.selectLanguage(language.name, index)}>
            {language.native}
          </button>
        </li>
      )
    }
  }

  stringEndsWith (string, suffix) {
    return string.match(suffix + '$') == suffix;
  }

  render () {
    const {
      ipData,
      location,
      languageSelection
    } = this.props;
    const hasCountryName = languageSelection.hasOwnProperty(ipData.country_name);
    const isFrontpage = location.pathname === '/';
    const isIntro = this.stringEndsWith(location.pathname, '/intro');
    const indoorGenLogo = isIntro || isFrontpage
      ? indoorGenWhite
      : indoorGenBlack;

    return (
      <header className={cx('stage-header', {
        'stage-header--transparent': isIntro || isFrontpage
      })}>
        <div className="container">
          <div className="stage-header__inner">
            {isFrontpage &&
              <ul className={cx('languages', {
                'hidden': !hasCountryName
              })}>
                {this.buildLanguageSelection(hasCountryName)}
              </ul>
            }
            <a href="https://www.velux.com/indoorgeneration" rel="noopener noreferrer" target="_blank" className="u-img-link">
              <img src={veluxLogo} alt="Velux logo" />
            </a>
            <a href="https://www.velux.com/indoorgeneration" rel="noopener noreferrer" target="_blank" className="js-indoor-generation-logo u-img-link">
              <img src={indoorGenLogo} alt="The Indoor Generation logo" />
            </a>
          </div>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  ipData: PropTypes.object.isRequired,
  languageSelection: PropTypes.object.isRequired,
  setUserLanguage: PropTypes.func.isRequired
};

export default withRouter(Header);
