import {
  GET_QUESTIONS_BEGIN,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_FAILURE,
  GET_SCENE_BEGIN,
  GET_SCENE_SUCCESS,
  GET_SCENE_FAILURE,
  ADD_ANSWER,
  UPDATE_ANSWER,
  SET_FIGURE_ANIMATION_INDEXES,
  UPDATE_FIGURE_ANIMATION_INDEXES,
  SET_MULTIPLE_FIGURE_COUNT,
  UPDATE_MULTIPLE_FIGURE_COUNT,
  RESET_MULTIPLE_FIGURE_COUNT,
  DELETE_MULTIPLE_FIGURE_COUNT,
  GET_TRACKING_DETAILS_BEGIN,
  GET_TRACKING_DETAILS_SUCCESS,
  GET_TRACKING_DETAILS_FAILURE
} from '../actions/questions';

const initialState = {
  loadingQuestions: false,
  loadingScene: true,
  loadingTracking: false,
  questions: {},
  sceneData: {},
  answers: [],
  figureIndexes: [],
  multipleFigureCount: [],
  totalQuestions: 12,
  currentQuestionCount: 0,
  trackingDetails: {},
  error: null
};

export default function questionsReducer (state = initialState, action) {
  switch(action.type) {

  case GET_QUESTIONS_BEGIN:
    return {
      ...state,
      loadingQuestions: true
    };

  case GET_QUESTIONS_SUCCESS:
    return {
      ...state,
      loadingQuestions: false,
      questions: action.data
    };

  case GET_QUESTIONS_FAILURE:
    return {
      ...state,
      loadingQuestions: false,
      error: action.error
    };

  case GET_SCENE_BEGIN:
    return {
      ...state,
      loadingScene: true,
      sceneData: {}
    };

  case GET_SCENE_SUCCESS:
    return {
      ...state,
      loadingScene: false,
      sceneData: action.data,
      figureIndexes: []
    };

  case GET_SCENE_FAILURE:
    return {
      ...state,
      loadingScene: false,
      error: action.error
    };

  case ADD_ANSWER:
    return {
      ...state,
      currentQuestionCount: state.currentQuestionCount + action.count,
      answers: [
        ...state.answers,
        {
          key: action.key,
          score: action.score
        }
      ]
    };

  case UPDATE_ANSWER:
    return {
      ...state,
      currentQuestionCount: state.currentQuestionCount + action.count,
      answers: state.answers.map(answer => {
        if (answer.key === action.key) {
          return {
            key: action.key,
            score: action.score
          };
        }
        return answer;
      })
    };

  case SET_FIGURE_ANIMATION_INDEXES:
    return {
      ...state,
      figureIndexes: action.indexes
    };

  case UPDATE_FIGURE_ANIMATION_INDEXES:
    return {
      ...state,
      figureIndexes: [
        ...state.figureIndexes,
        ...action.indexes
      ]
    };

  case SET_MULTIPLE_FIGURE_COUNT:
    return {
      ...state,
      multipleFigureCount: [
        ...state.multipleFigureCount,
        action.figure
      ]
    };

  case UPDATE_MULTIPLE_FIGURE_COUNT:
    return {
      ...state,
      multipleFigureCount: state.multipleFigureCount.map((figure, index) => {
        if (index === action.index) {
          return {
            ...figure,
            count: figure.count + action.count
          };
        }
        return figure;
      })
    };

  case RESET_MULTIPLE_FIGURE_COUNT:
    return {
      ...state,
      multipleFigureCount: state.multipleFigureCount.map(figure => {
        return {
          ...figure,
          count: 0
        }
      })
    };

  case DELETE_MULTIPLE_FIGURE_COUNT:
    return {
      ...state,
      multipleFigureCount: []
    };

  case GET_TRACKING_DETAILS_BEGIN:
    return {
      ...state,
      loadingTracking: true
    };

  case GET_TRACKING_DETAILS_SUCCESS:
    return {
      ...state,
      loadingTracking: false,
      trackingDetails: action.data
    };

  case GET_TRACKING_DETAILS_FAILURE:
    return {
      ...state,
      loadingTracking: false,
      error: action.error
    };

  default:
    return state;
  }
}
